<template lang="pug">
    .main-wrapper.especialista-visualizar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "especialista" }'> Especialistas</router-link> /
                        <b>Visualizar</b>
        
        Dialog(v-if='model' header='Remover especialista' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover o especialista <b>{{ model.nm_especialista }}</b>?
            .ta-center.my-4
                Button.p-button-danger(label='Remover' @click='remove()')

        ProgressBar(v-if='waiting' mode="indeterminate")
        Panel.my-2(v-else header='Detalhes do Especialista')
            .p-grid.p-fluid.p-justify-center.p-align-center
                .p-col-8.p-md-2.ta-center.pa-4
                    .avatar-wrapper
                        img(:src='model.aq_foto.split("/").pop() == "foto-default.jpeg" ?\
                            require("./../../assets/img/profile-pic-1.jpg") :\
                            `${ mediaUrl }${ model.aq_foto }`')
                .p-col-12.p-md-8
                    .p-grid.p-fluid.box-info
                        .p-col-12.p-md-6.aux-2
                            p <b>Nome: </b> {{ model.nm_especialista }}
                        .p-col-12.p-md-6.aux-2
                            p <b>CPF: </b> {{ model.nr_cpf_f }}
                        .p-col-12.p-md-6.aux-2
                            p <b>E-mail: </b> {{ model.ds_email }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Data de nascimento: </b> {{ model.dt_nascimento_f }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Sexo: </b> {{ model.ie_sexo_f }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Conselho: </b> {{ model.nm_conselho }} {{ model.nr_conselho }} / {{ model.cd_uf_conselho?.ds_sigla }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Endereço: </b>
                                span(v-if='model.nm_rua') Rua {{ model.nm_rua }}, Nº {{ model.nr_numero }}, Bairro {{ model.nm_bairro }}, CEP {{ model.nr_cep_f }},&nbsp;
                                span {{ model.cd_cidade.nm_cidade }} - {{ model.cd_cidade.ds_uf }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Telefone: </b> {{ model.nr_telefone_f }}
                        .p-col-12.p-md-6.aux-2(v-if='model.ds_observacao')
                            p <b>Observações: </b> {{ model.ds_observacao }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Habilitado: </b> {{ model.ie_status ? 'Sim' : 'Não' }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Data de registro: </b> {{ model.dt_criado_f }}
            .ta-center.my-4
                Button.mx-2(label='Editar' icon='jam jam-write' @click="$router.push(`/especialista/salvar/${ model.id }/`)")
                Button.mx-2.p-button-danger(label='Apagar' icon='jam jam-trash' @click='dialogApagar = true')

</template>

<style lang="scss">
    .especialista-visualizar {
        .avatar-wrapper {
            border-radius: 50%;
            overflow: hidden;
            font-size: 0;
            max-width: 200px;
            img {
                width: 100%;
            }
        }
        .aux-2 {
            padding: 2px 10px;
        }
        .box-info {
            padding: 10px 20px;
        }
    }
</style>

<script>
    import Panel from 'primevue/panel'
    import ProgressBar from 'primevue/progressbar'
    import Button from 'primevue/button'
    import Dialog from 'primevue/dialog'

    import moment from 'moment'
    import { Especialista } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    export default {
        data () {
            return {
                model: null,
                waiting: true,
                mediaUrl: wsConfigs.getMediaUrl(),
                dialogApagar: false
            }
        },
        components: {
            Panel, ProgressBar, Button, Dialog
        },
        created () {

            if (isNaN(this.$route.params.id)) this.$router.go(-1)

            Especialista.find(parseInt(this.$route.params.id)).then(response => {
                if (response.status == 200) {
                    this.model = response.data

                    this.model.dt_criado_f = moment(this.model.dt_criado).format('DD/MM/YYYY - HH:mm:ss')
                    this.model.nr_cpf_f = this.model.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    this.model.dt_nascimento_f = moment(this.model.dt_nascimento).format('DD/MM/YYYY')
                    this.model.ie_sexo_f = this.model.ie_sexo == 'M' ? 'Masculino' : 'Feminino'
                    if (this.model.nr_cep) this.model.nr_cep_f = this.model.nr_cep.replace(/(\d{5})(\d{3})/, '$1-$2')
                    this.model.nr_telefone_f = this.model.nr_telefone.replace(/(\d{2})(\d)/, '($1) $2')

                    this.waiting = false
                } else this.$router.go(-1)
            })
        },
        methods: {
            remove () {
                Especialista.remove(this.model.id).then(response => {
                    if (response.status == 200) {
                        this.$toast.info('Especialista removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.$router.push({ name: 'especialista' })
                    }
                })
            }
        }
    }
</script>